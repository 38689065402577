.Footer {
  position: absolute;
  z-index: 20;
  bottom: 0;
  padding: 4px 10%;
  box-sizing: border-box;
  width: 100%;
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.FooterElements {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 0 auto;
  color: #00005B;
}

.FooterElements a {
  color: inherit;
  text-decoration: none;
}
