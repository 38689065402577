.Home {
  margin: 0;
  text-align: center;
  padding-top: 40px;
}

.TemporaryMessage {
  color: #00005B;
  font-weight: bold;
}
