.Toolbar {
  position: absolute;
  z-index: 10;
  padding: 20px 10%;
  box-sizing: border-box;
  width: 100%;
}

.SiteName {
  color: #000099;
  font-family: cooper-black-std, serif;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}
